import axios from "axios"
import { API } from "../../Config/config"

export const getContent = (query) => {
    const photosRequest = axios.get(`${API.pixabayApiUrl}/?q=${query}&key=${API.pixabayApiKey}`);
    const videosRequest = axios.get(`${API.pixabayApiUrl}/videos/?q=${query}&key=${API.pixabayApiKey}`);

    const response = Promise.all([photosRequest, videosRequest])
        .then(([photosRes, videosRes]) => {
            const photos = photosRes.data.hits; // Photos array
            const videos = videosRes.data.hits; // Videos array

            // Combine photos and videos into one result
            return [...photos, ...videos];
        })
        .catch((e) => {
            console.error('Error fetching content:', e);
            return e;
        });

    return response;
};
