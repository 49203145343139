import React, { useRef, useState } from 'react'
import { getContent } from '../../Services/Pixabay/ContentRecommentdationService';
import './ContentReccomendation.css';

export default function ContentRecomendation() {

    const [data, setData] = useState([]);

    const searchRef = useRef();

    const searchContent = async () => {
        const response = await getContent(searchRef.current.value);
        console.log(response);
        setData(response);
    }

  return (
    <div className='content-rec'>
      <h3>Get Content Inspiration</h3>
      <p>Royality free images and videos</p>
      <div>
        <input type='text' placeholder='Search..' ref={searchRef}/>
        <button onClick={searchContent}> Search </button>
      </div>

      <br/> 

      <div>
        {data.map((item) => {
            return(
                <div>
                    {item.type === "film"?
                    <>
                    <video src={item.videos?.small?.url} controls height={300} alt='large' width={300}></video>
                    </>:
                    <>
                    <img src={item.largeImageURL} width={300}/>
                    </>}
                </div>
            )
        })}
      </div>
    </div>
  )
}
