import React, { useState } from "react";
import "./instagram.css";

const Instagram = () => {

  const instagramClientId = "483724304815666";
  const redirectUri = encodeURIComponent('https://localhost:3000/auth/instagram/callback');

  const handleLogin = () => {
const instagramAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${instagramClientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
    window.location.href = instagramAuthUrl;
  }


  return (
    <div className="instagram-container">
      <h2>Instagram API Integration</h2>
      <button onClick={handleLogin}>Login from insta</button>
    </div>
  );
};

export default Instagram;
