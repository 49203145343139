import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const InstagramCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      // Send the code to your backend
      fetch('http://localhost:8080/auth/instagram', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
      .then(response => response.json())
      .then(data => {
        // Handle the response from your backend
        console.log(data);
        // Redirect or update state as needed
        navigate('/'); // Redirect to home page after successful login
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  }, [location, navigate]);

  return <div>Processing Instagram login...</div>;
};

export default InstagramCallback;

