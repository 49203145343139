import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Registration from "./Registration";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Analytics from "./components/Facebook/Analytics/Analytics";
import ContentLibrary from "./components/ContentLibrary";
import ContentSchedule from "./ContentSchedule";
import Instagram from "./components/instagram";
import Facebook from "./components/Facebook";
import Sidebar from "./components/Sidebar";
import Canvas from "./components/Canvas";
import PostCanvas from "./components/PostCanvas";
import VideoCanvas from "./components/VideoCanvas";
import "./App.css";
import UserContent from "./components/UserContent";
import ContentRecomendation from "./components/ContentReccomendation/ContentRecomendation";
import InstagramCallback from "./components/InstagramCallback";
import Challenges from "./components/Challenges/Challenges";
import LeaderBoard from "./components/LeaderBoard/LeaderBoard";

function App() {
  const id = localStorage.getItem("userId");

  return (
    <Router>
      <div className="App">
        <Sidebar />
        <div className="main-content">
          <Routes>
            {id ? (
              <>
                <Route path="/" element={<Navigate to="/home" />} />{" "}
                {/* Now redirects to login */}
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/login" />} />{" "}
                {/* Now redirects to login */}
              </>
            )}
            <Route path="/home" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/content-library" element={<ContentLibrary />} />
            <Route path="/schedule" element={<ContentSchedule />} />
            <Route path="/instagram" element={<Instagram />} />
            <Route path="/facebook" element={<Facebook />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/post-canvas" element={<PostCanvas />} />
            <Route path="/video-canvas" element={<VideoCanvas />} />
            <Route path="/canvas" element={<Canvas />} />
            <Route path="/usercontent" element={<UserContent />} />
            <Route path="/contentreccomendation" element={<ContentRecomendation />} />
            <Route path="/auth/instagram/callback" element={<InstagramCallback />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />            
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
