import React, { useEffect, useState } from "react";
import "./ContentScheduleForm.css";
import axios from "axios";
import { API } from "../../Config/config";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ContentRecomendation from "../ContentReccomendation/ContentRecomendation";

export default function ContentScheduleForm({
  pageName,
  pageId,
  pageAccessToken,
}) {
  const [content, setContent] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [posted, setPosted] = useState([]);
  const [media, setMedia] = useState({ type: "text", url: "" });
  const userId = localStorage.getItem("userId");

  const uploadToCloudinary = async (e) => {
    try {
      const file = e.target.files[0];
      const fileType = file.type.startsWith("video") ? "video" : "image";

      const formData = new FormData();
      formData.append("file", file); // Directly upload file without base64 conversion
      formData.append("upload_preset", "bhhbjgqb");

      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dzusmmrmh/${fileType}/upload`;
      const cloudinaryRes = await axios.post(cloudinaryUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const mediaUrl = cloudinaryRes.data.secure_url;
      setMedia({ type: fileType, url: mediaUrl });
      console.log("Uploaded media URL:", mediaUrl);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const addToDb = async () => {
    const payload = {
      scheduledDate: new Date(`${date}T${time}`),
      mediaUrl: media.url,
      mediaType: media.type,
      access_token: pageAccessToken,
      pageId,
      content,
    };

    axios
      .post(`${API.apiUrl}/schedulecontent`, payload)
      .then((res) => {
        console.log(res);
        NotificationManager.success("Content has been scheduled!");

        axios
          .post(`${API.apiUrl}/checkchallenges/${userId}`, { mediaType: media })
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });

        setContent("");
        setDate("");
        setTime("");
        setMedia({ type: "", url: "" });
      })
      .catch((e) => {
        console.log(e);
        NotificationManager.error("Something went wrong!");
      });
  };

  const [currentDate, setCurrentDate] = useState("");

  // Set the min date to today when the component mounts
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    setCurrentDate(today); // Set today's date as the default value
  }, []);
  return (
    <div>
      <NotificationContainer />
      <div className="schedule-container">
        <div className="form">
          <p>Connected to {pageName}</p>
          <h2>Content Schedule Page</h2>
          <textarea
            placeholder="Content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <input type="file" name="media" onChange={uploadToCloudinary} />
          <br />
          <br />
          {media.url &&
            (media.type === "image" ? (
              <img src={media.url} alt="post" width={300} />
            ) : (
              <video src={media.url} controls width={300} />
            ))}
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min={new Date().toISOString().split("T")[0]} 
          />
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
          <button onClick={addToDb}>Schedule</button>
        </div>
        <div className="posted-content">
          <h3>Scheduled Content</h3>
          {scheduledPosts.map((post, index) => (
            <div key={index}>
              <p>{post.content}</p>
              <p>
                {post.date} at {post.time}
              </p>
            </div>
          ))}
          <h3>Posted Content</h3>
          {posted.map((post, index) => (
            <div key={index}>
              <p>{post.content}</p>
              <p>Posted</p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <ContentRecomendation />
      </div>
    </div>
  );
}
