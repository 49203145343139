import React, { useEffect, useState } from 'react';
import { Trophy, Medal, Award, User } from 'lucide-react';
import './Leaderboard.css';
import { API } from '../../Config/config';


const Leaderboard = () => {

    const [entries, setEntries] = useState([]);

  const getData = async () => {
    const response = await fetch(`${API.apiUrl}/leaderboard`);
    const data = await response.json();
    console.log(data);
    
    setEntries(data);
  }

  useEffect(()=>{
    getData();
  }, [])

  return (
    <div className="leaderboard">
      <h2 className="leaderboard-title">Leaderboard</h2>
      <div className="leaderboard-entries">
        {entries.map((entry, index) => (
          <div key={entry.name} className="leaderboard-entry">
            <div className="entry-icon">
              {index === 0 ? (
                <Trophy className="icon gold" />
              ) : index === 1 ? (
                <Medal className="icon silver" />
              ) : index === 2 ? (
                <Award className="icon bronze" />
              ) : (
                <User className="icon default" />
              )}
            </div>
            <div className="entry-info">
              <h3 className="entry-username">{entry.userName}</h3>
              <p className="entry-points">{entry.points} points</p>
            </div>
            <div className="entry-rank">
              <span>{index + 1}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;

